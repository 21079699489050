import Gallery from "@browniebroke/gatsby-image-gallery"
import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"

const PicturesComponent = () => {
  const imagesQuery = useStaticQuery(graphql`
    query MyQuery {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "ueber-uns" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              thumb: gatsbyImageData(
                width: 270
                height: 270
                placeholder: BLURRED
              )
              full: gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `)

  const images = imagesQuery.allFile.edges.map(
    ({ node }) => node.childImageSharp
  )

  return (
    <Gallery
      images={images}
      imgClass="cursor-pointer rounded-lg object-center object-cover hover:opacity-75"
    />
  )
}

export default PicturesComponent
