import * as React from "react"

import Layout from "components/layout"
import PicturesComponent from "components/pictures/pictures"
import Headline from "components/general/headline"

const PicturesPage = () => {
  const seo = {
    metaTitle: "Fotos",
    metaDescription:
      "Fotos von Spieltagen, Trainings und Turnieren in denen die Faustball Mannschaft des MTV Vorsfelde in der Vergangenheit teilgenommen hat",
  }

  return (
    <Layout seo={seo} showSidebar={false}>
      <div className="main-box">
        <Headline headline="Fotos" subHeadline="Aus vergangenen Zeiten" />

        <div className="mt-2">
          <PicturesComponent />
        </div>
      </div>
    </Layout>
  )
}

export default PicturesPage
